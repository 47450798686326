<template>
  <v-dialog
    v-model="dialog"
    width="80%"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="!implicitActivity"
        color="light"
        icon
        elevation="0"
        data-cy="implicit_activity_btn"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ iconSelection() }}</v-icon>
      </v-btn>
      <ImplicitActivityTooltip
        v-else
        :message="implicitActivity.name"
        :icon="iconSelection()"
      >
      </ImplicitActivityTooltip>
    </template>
    <v-card data-cy="edit_implicit_activity_dialog">
      <v-card-title class="text-h5" fluid="">
        {{ $t('activities.modal.implicit_activities.title') }}
      </v-card-title>
      <v-container class="mx-0 px-3">
        <div class="ml-3 subtitle-2">
          {{ explicitName }}
        </div>
        <v-radio-group v-model="selectedActivityId">
          <v-row
            v-for="activity in implicitActivities"
            :key="activity.id"
            no-gutters
          >
            <v-col class="py-1 px-3">
              <v-radio
                :key="activity.id"
                :label="activity.name"
                :value="activity.id"
              ></v-radio>
            </v-col>
          </v-row>
        </v-radio-group>
      </v-container>
      <v-card-actions class="sticky">
        <v-spacer></v-spacer>
        <v-btn @click="cancel">
          {{ $t('global.cancel') }}
        </v-btn>
        <v-btn
          :disabled="!selectedActivityId"
          color="primary"
          data-cy="save_implicit_activity_btn"
          @click="save"
        >
          {{ $t('global.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import ImplicitActivityTooltip from '@/components/activities/ImplicitActivityTooltip.vue'

export default {
  name: 'ImplicitActivitiesDialog',
  components: { ImplicitActivityTooltip },
  props: {
    implicitActivity: {
      type: Object,
      default: null
    },
    explicitName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      selectedActivityId: null
    }
  },
  computed: {
    ...mapState('activities', ['implicitActivities']),
    ...mapGetters('activities', ['implicitActivityById']),
  },
  watch: {
    implicitActivity: {
      handler(implicitActivity) {
        this.selectedActivityId = implicitActivity.id
      }
    }
  },
  mounted() {
    if (this.implicitActivity) {
      this.selectedActivityId = this.implicitActivity.id
    }
  },
  methods: {
    iconSelection() {
      return this.implicitActivity ? 'mdi-eye' : 'mdi-plus-circle-outline'
    },
    save() {
      this.$emit('update', this.implicitActivityById(this.selectedActivityId))
      this.dialog = false
    },
    cancel() {
      if (this.implicitActivity) {
        this.selectedActivityId = this.implicitActivity.id
      } else {
        this.selectedActivityId = null
      }
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.sticky {
  position: sticky;
  bottom: 0;
}
</style>
