<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="light" icon elevation="0" data-cy="implicit_activity_tooltip" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ message }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'ImplicitActivitiesDialog',
  components: {},
  props: {
    icon: {
      type: String,
      required: true,
      default: 'mdi-eye'
    },
    message: {
      type: String,
      required: true,
      default: ''
    }
  }
}
</script>

<style scoped></style>
