<template>
  <div>
    <v-data-table
      :headers="visibleHeaders"
      :items="activitySelections"
      :sort-by.sync="sortBy"
      :sort-des.sync="sortDesc"
      :items-per-page="-1"
      :single-expand="true"
      hide-default-footer
      show-expand
      class="no-select print-hide-last-column"
    >
      <template v-slot:item.contextualized_title="{ item }">
        <div>
          <EditActivityNameDialog
            :item="item"
            :activity-selections="activitySelections"
            :variant="variant"
            :disabled="disabled"
          ></EditActivityNameDialog>
        </div>
      </template>

      <template v-slot:item.professions="{ item }">
        <span v-if="Object.values(professions).length > 0">
          {{ getProfessionsName(item.activity.professions) }}
        </span>
      </template>
      <template v-slot:item.training_Level="{ item }">
        <slot name="levelColumn" v-bind="{ item }"></slot>
      </template>
      <template v-slot:item.frequency="{ item }">
        <slot name="frequencyColumn" v-bind="{ item }"></slot>
      </template>
      <template v-slot:item.interest="{ item }">
        <slot name="interestColumn" v-bind="{ item }"></slot>
      </template>
      <template v-slot:header.implicit_activity="{ header }">
        <span class="print-hide-column">{{ header.text }}</span>
      </template>
      <template v-slot:item.implicit_activity="{ item }">
        <ImplicitActivityTableCell
          :implicit-activity="item.activity.implicit_activity"
          :explicit-activity="item.activity"
          class="print-hide-column"
        ></ImplicitActivityTableCell>
      </template>
      <template v-slot:item.actions="{ item }">
        <slot name="actionsColumn" v-bind="{ item }"></slot>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="visibleHeaders.length">
          <div>
            <div class="text--secondary pt-3">
              {{ $t('activity_details.original_name') }}
            </div>
            <div class="">
              {{ item.activity.name }}
            </div>
            <div class="text--secondary pt-3">
              {{ $t('activity_details.contextualized_name') }}
            </div>
            <div>
              <span v-if="item.contextualized_name">
                {{ item.contextualized_name }}
              </span>
              <span v-else>{{ $t('activity_details.none') }}</span>
            </div>
          </div>
          <div class="text--secondary pt-3">Activité implicite</div>
          <div class="pb-4">
            <span v-if="item.activity.implicit_activity">
              {{ item.activity.implicit_activity.name }}
            </span>
            <span v-else>{{ $t('activity_details.none') }}</span>
          </div>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EditActivityNameDialog from '@/components/activities/EditActivityNameDialog.vue'
import ImplicitActivityTableCell from '@/components/activities/ImplicitActivityTableCell.vue'

export const Variant = Object.freeze({ Offer: 1, JobApplication: 2 })

export default {
  name: 'ExplicitActivitiesTable',
  components: {
    EditActivityNameDialog,
    ImplicitActivityTableCell
  },
  props: {
    activitySelections: {
      type: Array,
      required: true
    },
    variant: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      expanded: [],
      sortBy: 'display_name',
      sortDesc: false,
      headers: [
        {
          text: 'Activité',
          align: 'start',
          sortable: true,
          value: 'display_name'
        },
        {
          text: 'Activité',
          align: 'start',
          sortable: true,
          value: 'activity.name'
        },
        {
          text: this.$t('activities.context.modify_header'),
          sortable: false,
          value: 'contextualized_title'
        },
        {
          text: 'Source',
          sortable: true,
          value: 'activity.professions[0].name',
          class: 'nowrap'
        },
        {
          text: 'Effort de formation',
          sortable: false,
          value: 'training_Level',
          width: '5%'
        },
        {
          text: 'Fréquence',
          sortable: false,
          value: 'frequency',
          width: '10%'
        },
        {
          text: 'Intérêt',
          sortable: true,
          value: 'interest',
          width: '15%'
        },
        {
          text: 'Activité implicite',
          sortable: false,
          value: 'implicit_activity',
          width: '10%'
        },
        {
          value: 'actions',
          align: 'right'
        }
      ]
    }
  },
  computed: {
    ...mapState('professions', ['professions']),
    offerVariant() {
      return this.variant === Variant.Offer
    },
    visibleHeaders() {
      if (this.variant === Variant.JobApplication)
        return this.headers.filter(
          header =>
            header.value !== 'training_Level' && header.value !== 'frequency' && header.value !== 'activity.name'
        )
      return this.headers.filter(
        header =>
          header.value !== 'interest' && header.value !== 'activity.name'
      )
    }
  },
  methods: {
    removeSelection(selectionId) {
      this.$emit('removeSelection', selectionId)
    },
    getProfessionsName(professionIds) {
      return professionIds
        .map(professionId => this.professions[professionId]?.name)
        .join(', ')
    }
  }
}
</script>

<style scoped>
div.no-select > div > table > tbody > tr:hover {
  background-color: transparent !important;
}
</style>
