<template>
  <v-card
    elevation="3"
    class="ma-5 pa-1"
    data-cy="activity_card"
    @click="clicked"
  >
    <v-row align="center">
      <v-col cols="1">
        <v-btn v-show="!selected" x-large icon>
          <v-icon color="primary">mdi-plus-circle</v-icon>
        </v-btn>
        <v-btn v-show="selected" x-large icon>
          <v-icon color="primary">mdi-minus-circle</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        {{ activity.name }}
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    activity: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    ...mapActions('activities', [
      'addActivityToSelection',
      'removeActivityFromSelection'
    ]),
    clicked() {
      if (!this.selected) this.addActivityToSelection(this.activity)
      else this.removeActivityFromSelection(this.activity)
    }
  }
}
</script>

<style scoped></style>
