<template>
  <v-btn
    icon
    v-bind="size"
    :elevation="disabled ? 0: 1"
    :color="active ? 'primary' : 'gray'"
    :class="{'interest-button--disabled' : disabled}"
    @click="select"
  >
    <v-icon v-if="active"> mdi-heart </v-icon>
    <v-icon v-else> mdi-heart-outline </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'InterestButton',
  props: {
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    interest: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    size() {
      const size = { xs: 'x-small', sm: 'x-small', lg: 'small' }[
        this.$vuetify.breakpoint.name
      ]
      return size ? { [size]: true } : {}
    }
  },
  methods: {
    select() {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style scoped>
.interest-button--disabled {
  pointer-events: none;
}
</style>
