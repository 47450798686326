<template>
  <div>
    <Activity
      v-for="activity in availableExplicitActivities"
      :key="activity.id"
      :activity="activity"
      :selected="false"
    ></Activity>
    <div v-if="showAddCTA">
      <v-card
        elevation="3"
        class="align-center align-content-center ma-5"
        :loading="isValidationPending"
      >
        <v-card-title>{{
          $t('activities.modal.explicit_activities.new')
        }}</v-card-title>
        <v-card-text>
          <v-alert
            transition="fade-transition"
            :value="alert"
            :type="validationMessageType"
            text
            dense
            class="rounded-0"
          >
            {{ validationMessage }}
          </v-alert>
          <div>{{ activityToCreate }}</div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            color="primary"
            elevation="1"
            :disabled="isAddDisabled"
            :loading="createActivityLoading"
            @click="createActivity"
          >
            {{ $t('activities.modal.explicit_activities.add_btn') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div v-if="isSearchKeywordsSelected">
      <v-card elevation="3" class="align-center align-content-center ma-5">
        <v-card-title>{{
          $t('activities.modal.explicit_activities.already_selected')
        }}</v-card-title>
        <v-card-text>{{ activityToCreate }}</v-card-text>
      </v-card>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import debounce from 'lodash.debounce'
import Activity from '@/components/activities/Activity.vue'

export default {
  name: 'AvailableActivities',
  components: { Activity },
  props: {
    searchKeywords: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      createActivityLoading: false,
      alert: false,
      validationMessage: '',
      validationMessageType: 'warning',
      debounceFn: null,
      isValidationPending: false
    }
  },
  computed: {
    ...mapGetters('activities', ['availableExplicitActivities']),
    ...mapState('activities', ['selectedExplicitActivities']),
    syntaxicValidationEnabled() {
      return process.env.VUE_APP_SYNTAXIC_VALIDATION_ENABLED === 'true'
    },
    isSearchKeywordsSelected() {
      const selectedNames = []
      Object.values(this.selectedExplicitActivities).forEach(activity => {
        selectedNames.push(activity.name)
      })
      return selectedNames.includes(this.activityToCreate)
    },
    showAddCTA() {
      return (
        this.searchKeywords !== '' &&
        this.searchKeywords !== null &&
        !this.isSearchKeywordsSelected
      )
    },
    isAddDisabled() {
      if (this.syntaxicValidationEnabled) {
        return this.validationMessageType !== 'info' || this.isValidationPending
      }
      return false
    },
    activityToCreate() {
      if (this.searchKeywords) {
        return this.searchKeywords.charAt(0).toUpperCase() + this.searchKeywords.slice(1);
      }
      return ""
    }
  },
  watch: {
    searchKeywords() {
      if (this.showAddCTA) {
        if (this.syntaxicValidationEnabled) {
          this.debounceFn()
        }
      }
    }
  },
  mounted() {
    this.debounceFn = debounce(this.handleValidation, 1000)
  },
  methods: {
    ...mapActions('activities', [
      'createExplicitActivity',
      'validateExplicitActivity'
    ]),
    showAlert() {
      this.alert = true
    },
    createActivity() {
      this.createActivityLoading = true
      this.createExplicitActivity({
        name: this.searchKeywords
      })
        .then(response => {
          this.$emit('new', response.data)
        })
        .catch(error => {
          // This error catching is a double safety and should not happen,
          // as the "add" CTA is displayed only if the activity doesn't exist.
          // But maybe someday a race condition can make it happen.

          // eslint-disable-next-line prefer-destructuring
          this.validationMessage = error.response.data.name[0]
          this.showAlert()
        })
        .finally(() => {
          this.validationMessage = this.$t("activities.modal.creationSuccess")
          this.validationMessageType = 'success'
          this.createActivityLoading = false
        })
    },
    handleValidation() {
      // Wait for at least one space
      if (!(/\s/.test(this.activityToCreate))) return

      this.isValidationPending = true
      this.validateExplicitActivity(this.activityToCreate).then(response => {
        if (response.data.error) {
          // eslint-disable-next-line prefer-destructuring
          this.validationMessage = response.data.error
          this.validationMessageType = 'warning'
          this.isValidationPending = false
          this.showAlert()
        } else {
          this.validationMessage = this.$t(
            'activities.modal.explicit_activities.syntax_valid'
          )
          this.validationMessageType = 'info'
          this.isValidationPending = false
          this.showAlert()
        }
      })
    }
  }
}
</script>

<style scoped></style>
