/**
 * Handle interactions between un-related components with the global
 * event bus pattern.
 * See: https://alligator.io/vuejs/global-event-bus/
 */
import Vue from 'vue';

const eventBus = new Vue();

export default eventBus;
