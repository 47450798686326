<template>
  <v-card no-gutters class="pa-8">
    <v-row v-if="jobParent">
      <v-col class="py-0">
        <h3 v-if="!dataIsLoading">
          {{ jobParent }}
        </h3>
      </v-col>
    </v-row>
    <v-row v-if="!dataIsLoading" align="center">
      <v-col class="py-0">
        <h2>{{ title }}</h2>

        <div v-if="subtitle" class="text--secondary mt-2">
          <v-icon v-if="withLockIcon" class="pb-1 pr-2">mdi-lock</v-icon>
          <span v-html="subtitle" />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'ActivitiesHeader',
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    },
    jobParent: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    withLockIcon: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
  },
}
</script>
