<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn :disabled="disabled" color="primary" v-bind="attrs" v-on="on">
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </template>
    <v-card color="background" class="pa-8">
      <v-card-title class="text-h5">{{
        $t('activities.delete_title')
      }}</v-card-title>
      <v-card-text>
        {{ $t('activities.delete_confirm') }} "{{ selection.activity.name }}" ?
      </v-card-text>
      <v-card-actions class="mt-2">
        <v-spacer></v-spacer>
        <v-btn @click="cancel">
          {{ $t('global.no') }}
        </v-btn>
        <v-btn color="primary" @click="removeSelection">
          {{ $t('global.yes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus from '@/eventBus'

export default {
  name: 'DeleteActivityDialog',
  props: {
    selection: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialog: false
    }
  },

  mounted() {
    eventBus.$on('closeDeleteDialog', this.closeDialog)
  },
  methods: {
    cancel() {
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
    },
    removeSelection() {
      this.$emit('removeSelection', this.selection.id)
    }
  }
}
</script>

<style scoped></style>
