<template>
  <v-card height="100%" class="pt-3" rounded="false">
    <Activity
      v-for="activity in selectedExplicitActivities"
      :key="activity.key"
      :activity="activity"
      :selected="true"
    ></Activity>
    <div
      v-if="
        selectedExplicitActivities === null ||
        selectedExplicitActivities.length === 0
      "
    ></div>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import Activity from '@/components/activities/Activity.vue'

export default {
  name: 'SelectedActivities',
  components: { Activity },
  computed: {
    ...mapState('activities', ['selectedExplicitActivities'])
  }
}
</script>

<style scoped></style>
